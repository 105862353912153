import React, { ReactNode, useState } from 'react';
import { Element, PhoneToggle } from './WhatsAppDropup.styles';
import WhatsAppIcon from '@/components/icons/WhatsAppIcon/WhatsAppIcon';
import DropdownOfficePhone from '@/components/navigation/ContactMenu/DropdownOfficePhone/DropdownOfficePhone';
import { Dropdown } from '@/components/dropdown';

export interface WhatsAppFABProps {
  className?: string;
  children?: ReactNode;
}

const WhatsAppDropup = (props: WhatsAppFABProps) => {
  const [open, setOpen] = useState(false);
  const toggle = () => {
    setOpen((prevState) => !prevState);
  };

  return (
    <Element>
      <Dropdown
        spacing="base"
        top="auto"
        bottom="110%"
        render={(props) => (
          <PhoneToggle {...props}>
            <WhatsAppIcon />
          </PhoneToggle>
        )}
      >
        <DropdownOfficePhone displayType="whatsapp" aria-hidden={true} />
      </Dropdown>
    </Element>
  );
};

export default WhatsAppDropup;
