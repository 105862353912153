import React, { ReactNode } from 'react';

export interface WhatsAppIconProps {
  size?: number;
  className?: string;
  children?: ReactNode;
}

const WhatsAppIcon = ({ size = 54, ...props }: WhatsAppIconProps) => {
  return (
    <svg width={size} height={size + 1} {...props} viewBox="0 0 54 55" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_f_1220_46111)">
        <path
          d="M0.742094 27.188C0.740404 31.804 1.94325 36.3098 4.22963 40.2816L0.522705 53.8525L14.3741 50.2109C18.1904 52.2977 22.4872 53.3974 26.8599 53.3991H26.8712C41.2715 53.3991 52.9939 41.6489 53 27.2093C53.0024 20.2109 50.287 13.6307 45.3541 8.68044C40.4205 3.73029 33.8604 1.00278 26.8712 0.999687C12.4686 0.999687 0.74782 12.748 0.742094 27.188Z"
          fill="black"
          fillOpacity="0.2"
        />
      </g>
      <path
        d="M1.67979 27.1357C1.67847 31.5751 2.83842 35.91 5.04425 39.7308L1.46875 52.7861L14.8288 49.283C18.5096 51.2892 22.6538 52.3478 26.8715 52.3493H26.8828C40.7719 52.3493 52.0781 41.0469 52.0839 27.1553C52.0867 20.4237 49.4675 14.094 44.709 9.33198C39.9513 4.56997 33.6236 1.9461 26.8819 1.9431C12.991 1.9431 1.68542 13.2446 1.67979 27.1358"
        fill="url(#paint0_linear_1220_46111)"
      />
      <path
        d="M0.779994 27.1269C0.778304 31.7265 1.98012 36.2165 4.26443 40.1743L0.560791 53.6973L14.4001 50.0685C18.213 52.1479 22.506 53.2438 26.8749 53.2454H26.8862C41.2738 53.2454 52.9859 41.5367 52.992 27.1481C52.9945 20.1744 50.2814 13.6174 45.3529 8.68457C40.4237 3.75188 33.8693 1.03414 26.8862 1.03095C12.4962 1.03095 0.785721 12.7378 0.7799 27.1269H0.779994ZM9.02138 39.4928L8.50477 38.6723C6.33264 35.2186 5.18593 31.2272 5.18781 27.1287C5.19241 15.1689 14.9257 5.43895 26.8945 5.43895C32.6904 5.4413 38.1377 7.70045 42.2348 11.8004C46.3315 15.9 48.5859 21.3501 48.5842 27.1464C48.5792 39.1066 38.8455 48.8379 26.8863 48.8379H26.8779C22.9836 48.8358 19.1647 47.7903 15.8339 45.8137L15.0413 45.344L6.82878 47.4971L9.02138 39.4928Z"
        fill="url(#paint1_linear_1220_46111)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.3613 16.2175C19.8727 15.1313 19.3583 15.1095 18.8935 15.0904C18.5133 15.0742 18.078 15.0752 17.6434 15.0752C17.2086 15.0752 16.5018 15.2386 15.9041 15.8913C15.3061 16.544 13.6208 18.1219 13.6208 21.3312C13.6208 24.5408 15.9585 27.6418 16.2843 28.0774C16.6105 28.5124 20.797 35.3086 27.427 37.9232C32.9372 40.0961 34.0585 39.6639 35.2545 39.5552C36.4505 39.4465 39.1138 37.9776 39.6573 36.4544C40.201 34.9314 40.201 33.6261 40.0379 33.3532C39.8747 33.0813 39.4399 32.9183 38.7875 32.592C38.1351 32.2658 34.9282 30.6875 34.3302 30.4701C33.7323 30.2527 33.2973 30.144 32.8625 30.797C32.4275 31.4495 31.1783 32.9183 30.7976 33.3533C30.4171 33.7892 30.0366 33.8435 29.3841 33.5173C28.7318 33.1901 26.6309 32.502 24.1387 30.2799C22.1995 28.5511 20.8905 26.4159 20.5097 25.7629C20.1293 25.1105 20.4691 24.7571 20.7963 24.4319C21.0892 24.1397 21.4487 23.6706 21.7748 23.2896C22.1003 22.9088 22.2091 22.6369 22.4265 22.202C22.6439 21.7664 22.5352 21.3854 22.3722 21.0593C22.2091 20.7331 20.9414 17.5072 20.3613 16.2175Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_f_1220_46111"
          x="0.147196"
          y="0.624003"
          width="53.2283"
          height="53.604"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="0.187754" result="effect1_foregroundBlur_1220_46111" />
        </filter>
        <linearGradient
          id="paint0_linear_1220_46111"
          x1="26.7762"
          y1="52.7862"
          x2="26.7762"
          y2="1.94305"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#20B038" />
          <stop offset="1" stopColor="#60D66A" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1220_46111"
          x1="26.7765"
          y1="53.6974"
          x2="26.7765"
          y2="1.03098"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9F9F9" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default WhatsAppIcon;
