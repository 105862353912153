import styled, { css } from 'styled-components';

export const Element = styled.div`
  ${({ theme }) => {
    return css`
      position: fixed;
      bottom: 1rem;
      right: 1rem;
      text-align: initial;
      outline: none;
      user-select: none;

      z-index: 1000;

      @media screen and (min-width: ${theme.breakpoint.sm}) {
        bottom: 2rem;
      }
    `;
  }};
`;

export const PhoneToggle = styled.button<any>`
  outline: none;
  user-select: none;
`;
